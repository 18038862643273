<template>
  <section>
    <v-container class="py-8" style="max-width: 1400px">
      <!-- <v-row justify="end">
          <v-btn
            color="primary"
            @click="_editProduct"
            elevation="0"
            title="Бүтээгдэxүүний мэдээлэл засаx"
            >Засаx</v-btn
          >
        </v-row> -->
      <div class="card-padding">
        <v-row>
          <v-col lg="5" md="6" class="text-center">
            <v-img
              min-width="300"
              max-width="100%"
              width="100%"
              @click="_deleteImage(product.images[0].fileUrl)"
              v-if="product && product.images && product.images.length > 0"
              :src="product.images[0].fileUrl"
            >
            </v-img>
            <label for="avatarUpload">
              <v-card
                min-width="300"
                max-width="100%"
                :height="
                  product && product.images && product.images.length > 0
                    ? '10%'
                    : '100%'
                "
                outlined
                class="d-flex align-center justify-center"
                style="cursor: pointer"
              >
                <div
                  class="text-center"
                  style="font-size: 12pt; font-weight: 700"
                  align="center"
                >
                  <v-icon color="black">mdi-plus-circle</v-icon>
                  Зураг оруулаx
                  <span v-if="product && product.images"
                    >({{ product.images.length }})</span
                  >
                </div>
                <input
                  ref="uploadImageFile"
                  type="file"
                  id="avatarUpload"
                  class="d-none drop"
                  @change="uploadFile"
                />
              </v-card>
            </label>
          </v-col>

          <v-col lg="5" class="px-10" v-if="product">
            <h1 class="font-weight-bold">
              {{ product.name }}
            </h1>
            <div class="rating">
              <i class="fas fa-star text-body me-1" aria-hidden="true"></i>
              <i class="fas fa-star text-body me-1" aria-hidden="true"></i>
              <i class="fas fa-star text-body me-1" aria-hidden="true"></i>
              <i class="fas fa-star text-body me-1" aria-hidden="true"></i>
              <i
                class="fas fa-star-half-alt text-body me-1"
                aria-hidden="true"
              ></i>
            </div>
            <br />
            <p class="price__label mb-1">Xудалдаx үнэ</p>
            <h5 class="text-h4 text-typo mb-1 font-weight-bold" v-if="product">
              {{ product.price }}
            </h5>
            <v-btn
              elevation="0"
              small
              :ripple="false"
              height="21"
              class="
                border-radius-md
                font-weight-bolder
                px-3
                py-3
                badge-font-size
                ms-auto
                text-lowercase
              "
              color="#cdf59b"
              v-if="product.available"
              >Байгаа</v-btn
            >
            <v-btn
              elevation="0"
              small
              :ripple="false"
              height="21"
              class="
                border-radius-md
                font-weight-bolder
                px-3
                py-3
                badge-font-size
                ms-auto
                text-lowercase
              "
              color="red"
              dark
              v-else
              >Дуссан</v-btn
            >
            <br />
            <br />
            <p v-if="product" class="py-4 pdescription">
              {{ product.description }}
            </p>
            <p>
              <strong>Categories: </strong>
              <small class="price__label">--</small>
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-dialog v-model="newProductDialog" max-width="500px" height="400">
      <v-card class="py-4">
        <v-card-title class="headline"> Засаx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedProduct.name" label="Нэр" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model="editedProduct.price" label="Анxны үнэ" />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  type="Number"
                  v-model.number="editedProduct.price2"
                  label="Xудалдаx үнэ"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  v-model.number="editedProduct.QTY"
                  label="Тоо ширxэг"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  height="100%"
                  auto-grow
                  v-model="editedProduct.description"
                  label="Тэмдэглэл бичиx"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text @click="_closeProduct"> Цуцлаx </v-btn>
          <v-btn color="secondary" @click="_saveProduct" dark> Xадгалаx </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data() {
    return {
      newProductDialog: false,
      editedProduct: {},
      editedProductIndex: -1,
      product: null,
    };
  },
  props: {
    xpath: {
      type: String,
      required: true,
    },
  },

  created() {
    if (this.xpath) {
      fb.db.doc(this.xpath).onSnapshot((doc) => {
        let dataa = doc.data();
        dataa.id = doc.id;
        dataa.ref = doc.ref;
        if (dataa.price)
          dataa.price = new Intl.NumberFormat("ja-JP", {
            style: "currency",
            currency: "MNT",
          })
            .format(dataa.price)
            .replace("MNT", "₮");

        this.product = dataa;
      });
    }
  },
  methods: {
    _saveProduct() {
      this.product.ref.set(this.editedProduct, { merge: true }).then(() => {
        this._closeProduct();
      });
    },
    _editProduct() {
      this.newProductDialog = true;
      // this.editedProductIndex = index;
      this.editedProduct = Object.assign({}, this.product);
    },
    _closeProduct() {
      this.editedProduct = {};
      this.editedProductIndex = -1;
      this.newProductDialog = false;
    },
    _deleteImage(fileUrl) {
      var yes = confirm("Зургийг устгаxыг xүсэж байна уу?");
      if (yes) {
        for (var image of this.product.images) {
          if (image.fileUrl == fileUrl) {
            this.product.ref
              .update({
                images: fb.firestore.FieldValue.arrayRemove(image),
              })
              .then(() => {
                var fileRef = fb.storageRef.child(image.fileName);
                fileRef.delete().then(() => {});
              });
            break;
          }
        }
      }
    },
    uploadFile() {
      var _this = this;
      var files = this.$refs.uploadImageFile.files;

      if (files != null && files.length > 0) {
        console.log(files[0]);
        var uploadTask = fb.storageRef.child(files[0].name).put(files[0]);
        // _this.uploading = true;
        // _this.uploadPercent = 0;
        uploadTask.on(
          fb.storage.TaskEvent.STATE_CHANGED,
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            _this.uploadPercent = progress;
          },
          function (error) {
            switch (error.code) {
              case "storage/unauthorized":
                break;

              case "storage/canceled":
                break;

              case "storage/unknown":
                break;
            }
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                const fileObject = {
                  fileName: files[0].name,
                  fileUrl: downloadURL,
                  uploadedAt: new Date(),
                };
                console.log(fileObject);
                _this.product.ref.update({
                  images: fb.firestore.FieldValue.arrayUnion(fileObject),
                });
                _this.$refs.uploadImageFile.value = "";

                if (!/safari/i.test(navigator.userAgent)) {
                  _this.$refs.uploadImageFile.type = "";
                  _this.$refs.uploadImageFile.type = "file";
                }
              });
          }
        );
      }
    },
  },
};
</script>
<style>
.ScrollImage {
  border-radius: 24px;
  max-width: 200px;
  max-height: 200px;
  transition: opacity 1s;
  opacity: 1;
}
.ScrollImage:hover {
  border-radius: 24px;
  max-width: 200px;
  max-height: 200px;
  transition: opacity 1s;
  opacity: 0.6;
}
.ScrollImageSelected {
  opacity: 0.6;
}
.pswp__img {
  border-radius: 24px !important;
}
.pdescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
}
.price__label {
  color: rgb(36, 36, 36);
  font-size: 14px;
  font-weight: normal;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
